import React, { useCallback, useEffect, useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/solid";
import { scroller } from "react-scroll";
import LogoImage from "../images/logo.png";

const menu = [
  {
    id: "intro",
    name: "회사소개",
  },
  {
    id: "services",
    name: "서비스",
  },
  {
    id: "clients",
    name: "클라이언트",
  },
  {
    id: "contact",
    name: "문의하기",
  },
];

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [colorBg, setColorBg] = useState(false);

  const closeOnEscPress = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", closeOnEscPress);
    return () => {
      document.removeEventListener("keydown", closeOnEscPress);
    };
  }, [closeOnEscPress]);

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  const changeBackground = () => {
    if (window.scrollY >= 64) {
      setColorBg(true);
    } else {
      setColorBg(false);
    }
  };

  const scrollTo = (elementId) => {
    setIsMenuOpen(!isMenuOpen);
    scroller.scrollTo(elementId, {
      duration: 700,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -64,
    });
  };

  return (
    <>
      <header
        className={`flex justify-between items-center px-6 md:px-16 w-full h-16 fixed top-0 drop-shadow-md text-white z-50 ${
          colorBg && "bg-white"
        } transition-all duration-150 ease-in`}
      >
        <div className="flex items-center w-32 md:w-36 lg:w-40 h-full">
          <a href="/">
            <img src={LogoImage} alt="logo" />
          </a>
        </div>

        <nav className="hidden md:flex relative">
          {menu.map((item) => (
            <button
              className={`font-medium mx-5 py-5 px-1 ${
                colorBg && "text-main"
              } group relative hover:font-bold`}
              key={item.id}
              onClick={() => scrollTo(item.id)}
            >
              {item.name}
              <span className="absolute bottom-5 left-0 w-0 h-2 bg-yellow group-hover:opacity-40 transition-all group-hover:w-full "></span>
            </button>
          ))}
        </nav>

        <button
          className={`md:hidden ${colorBg && "text-main"}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <MenuIcon className="h-5 w-5" />
        </button>
      </header>

      {/* mobile dropdown */}
      <nav
        className={`py-3 px-3 md:hidden flex flex-col items-center bg-main text-white z-50 fixed w-full drop-shadow-xl ${
          isMenuOpen
            ? "opacity-100 visible top-0"
            : "opacity-0 invisible -top-96"
        } transition-all duration-200 ease-in`}
      >
        <button
          className="flex justify-end w-[90%]"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <XIcon className="h-5 w-5" />
        </button>
        {menu.map((item) => (
          <button
            className="p-2"
            key={item.id}
            onClick={() => scrollTo(item.id)}
          >
            {item.name}
          </button>
        ))}
      </nav>
    </>
  );
}
