import React from "react";
import icon1 from "../images/3-Services/5.png";
import icon2 from "../images/3-Services/6.png";
import icon3 from "../images/3-Services/7.png";
import icon4 from "../images/3-Services/8.png";
import icon5 from "../images/3-Services/9.png";
import icon6 from "../images/3-Services/10.png";
import icon7 from "../images/3-Services/11.png";
import icon8 from "../images/3-Services/12.png";

const listItems = [
  {
    imgFile: icon1,
    contents: ["앱 다운로드", "Incentive 캠페인"],
  },
  {
    imgFile: icon2,
    contents: ["앱 다운로드", "Non-Incentive 캠페인"],
  },
  {
    imgFile: icon3,
    contents: ["모바일 앱 트래픽", "수집 캠페인"],
  },
  {
    imgFile: icon4,
    contents: ["플레이스토어", "랭킹 상승"],
  },
  {
    imgFile: icon5,
    contents: ["모바일", "프로모션 캠페인"],
  },
  {
    imgFile: icon6,
    contents: ["트래킹 툴을 이용한", "데이터 분석"],
  },
  {
    imgFile: icon7,
    contents: ["모바일 마케팅", "전략 설정"],
  },
  {
    imgFile: icon8,
    contents: ["리타게팅 캠페인", "(PC/Mobile)"],
  },
];

export default function Services() {
  return (
    <section
      id="services"
      className="flex flex-col items-center h-[840px] md:h-[700px] lg:h-[calc(100vh_-_64px)] min-h-[650px] xl:max-h-[1000px]"
    >
      <header className="flex flex-col items-center justify-center px-14 md:px-20 py-10">
        <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-center mb-1">
          Creative Performance Marketing
        </h1>
        <h2
          className="md:text-lg lg:text-xl font-medium text-center"
          style={{ wordBreak: "keep-all" }}
        >
          원투애드는 앱 다운로드 마케팅과 마케팅 결과 분석을 전문적으로
          진행합니다.
        </h2>
        <hr className="border-t-[3px] h-[3px] w-[50px] mt-5"></hr>
      </header>

      <ul
        data-scroll
        className="grid grid-cols-2 md:grid-cols-4 lg:gap-x-6 px-[10%] py-8 md:py-16 lg:py-18 h-full"
      >
        {listItems.map((items) => (
          <li
            key={items.contents[1]}
            className="flex flex-col justify-center items-center"
          >
            <img
              data-scroll
              src={items.imgFile}
              alt="services"
              className="w-[10rem] lg:w-[11rem] -mt-20"
            />
            <p
              className="-mt-8 text-xs lg:text-tiny xl:text-base w-[90%] text-center
            "
              style={{ wordBreak: "keep-all" }}
            >
              {items.contents[0]}
              <br />
              {items.contents[1]}
            </p>
          </li>
        ))}
      </ul>
    </section>
  );
}
