/* global kakao */
import React, { useEffect, useRef } from "react";
import { debounce } from "debounce";
import { coordinateX, coordinateY } from "../const";

const { kakao } = window;

const options = {
  center: new kakao.maps.LatLng(coordinateX, coordinateY),
  level: 4,
};

export default function Map({ openKakaoMap }) {
  const container = useRef(null);

  useEffect(() => {
    showMap();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", debounce(showMap, 500));
    return () => {
      window.removeEventListener("resize", showMap);
    };
  }, []);

  const showMap = () => {
    const map = new kakao.maps.Map(container.current, options);
    map.setZoomable(false);
    const marker = new kakao.maps.Marker({
      position: map.getCenter(),
    });
    marker.setMap(map);
    const zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

    kakao.maps.event.addListener(marker, "click", openKakaoMap);
  };

  return (
    <div
      ref={container}
      className="w-[15rem] md:w-[17rem] lg:w-[19rem] 2xl:w-[20rem] h-[15rem] md:h-[17rem] lg:h-[19rem] 2xl:h-[20rem] rounded-2xl drop-shadow-lg"
    ></div>
  );
}
