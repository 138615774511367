import React from "react";
import bg from "../images/2-Intro/bg.jpg";
import icon1 from "../images/2-Intro/1.png";
import icon2 from "../images/2-Intro/2.png";
import icon3 from "../images/2-Intro/3.png";

export default function Intro() {
  return (
    <>
      <section
        id="intro"
        className="flex flex-col h-[calc(100vh_-_64px)] min-h-[1400px] lg:min-h-[800px] xl:min-h-[700px] max-h-[1300px] xl:max-h-[1000px]"
      >
        <header className="flex flex-col items-center justify-center bg-grey px-14 md:px-20 py-10">
          <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-center mb-1">
            No1. Performance Marketing Agency
          </h1>
          <h2 className="md:text-lg lg:text-xl font-medium">
            원투애드는 퍼포먼스 광고 에이전시입니다.
          </h2>
          <hr className="border-t-[3px] h-[3px] w-[50px] my-[4%] lg:my-[2%]"></hr>
          <h3 className="text-xs md:text-sm lg:text-base max-w-[1300px]">
            원투애드는 데이터에 기반한 광고 효과 분석을 통해 최고의 마케팅
            성과를 보여드립니다. 국내외 앞서가는 광고 기술을 가장 빠르게
            적용하고 있습니다.
          </h3>
          <h3 className="text-xs md:text-sm lg:text-base max-w-[1300px]">
            시장을 선도하고 싶다면 원투애드와 함께 하세요. 디지털 퍼포먼스
            마케팅 분야에서 커머스, 서비스, 게임을 총 망라하는 특별한 레퍼런스를
            보유하고 있습니다.
          </h3>
        </header>

        <ul className="flex flex-col h-full lg:flex-row  text-center lg:text-left pb-10 px-[5%] 2xl:px-[10%] justify-center">
          <li
            data-scroll
            className="flex flex-col items-center justify-center h-full w-full"
          >
            <img src={icon1} alt="intro" className="w-[12rem]" />
            <h2
              className="text-lg md:text-xl lg:text-2xl font-medium"
              style={{ marginTop: "-1rem", marginBottom: "0.7rem" }}
            >
              최신 기술 선도
            </h2>
            <p
              className="text-xs md:text-sm lg:text-base w-[90%]"
              style={{ wordBreak: "keep-all" }}
            >
              자체 개발팀을 보유한 원투애드는 앞서가는 기술력으로 클라이언트를
              지원해드립니다.
              <br />
              글로벌 트렌드를 가장 먼저 적용하며, 자체 매체를 개발할 정도의 기술
              역량을 광고주를 위해 사용합니다.
              <br />
              프로그래매틱 광고부터 복잡한 기술 연동 이슈의 해결까지 원투애드와
              함께 하세요.
            </p>
          </li>
          <li
            data-scroll
            className="flex flex-col items-center justify-center h-full w-full"
          >
            <img src={icon2} alt="intro" className="w-[12rem]" />
            <h2
              className="text-lg md:text-xl lg:text-2xl font-medium"
              style={{ marginTop: "-1rem", marginBottom: "0.7rem" }}
            >
              마케팅 인사이트 제공
            </h2>
            <p
              className="text-xs md:text-sm lg:text-base w-[90%]"
              style={{ wordBreak: "keep-all" }}
            >
              다양한 애널리틱스 도구를 활용하여 클라이언트의 데이터를 정밀하게
              분석합니다.
              <br />
              분석한 데이터에 기반하여 광고 효율을 세심하게 측정하며, 최적의
              매체 운영 전략을 수립합니다.
              <br />더 나아가 클라이언트에게 마케팅 인사이트를 제공하는 컨설팅
              파트너로서의 원투애드를 만나보세요.
            </p>
          </li>
          <li
            data-scroll
            className="flex flex-col items-center justify-center h-full w-full"
          >
            <img src={icon3} alt="intro" className="w-[12rem]" />
            <h2
              className="text-lg md:text-xl lg:text-2xl font-medium"
              style={{ marginTop: "-1rem", marginBottom: "0.7rem" }}
            >
              커스터마이징
            </h2>
            <p
              className="text-xs md:text-sm lg:text-base w-[90%]"
              style={{ wordBreak: "keep-all" }}
            >
              원투애드는 업종별, 규모별, 마케팅 단계별로 각양각색인 클라이언트의
              KPI에 따라 맞춤 마케팅 전략을 제안합니다.
              <br />
              수많은 기업의 퍼포먼스 마케팅을 진행하며 쌓인 노하우로 광고주
              KPI를 가장 빠르고, 효율적으로 달성해드립니다. 한정된 마케팅
              비용으로 최고의 성과를 내보세요.
            </p>
          </li>
        </ul>
      </section>

      <section
        className="flex flex-col items-center justify-center h-[calc(100vh_-_64px)] lg:h-[35rem] min-h-[35rem] bg-cover bg-right bg-blend-darken 
      text-white px-10"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundColor: "rgba(0,0,0,0.4)",
        }}
      >
        <h1 className="w-[95%] md:w-[85%] text-lg md:text-xl lg:text-2xl xl:text-3xl font-medium mb-1 text-center">
          The faster the digital market has changed, the faster OnetwoAd has
          grown.
        </h1>
        <p className="w-[88%] md:w-[75%] max-w-[1600px] text-xs md:text-base mt-8">
          스마트폰 이용자가 4천만명을 돌파했으며 계속해서 좋은 스마트폰 앱들이
          출시되고 있지만, 유저들의 선택을 받고 살아남는 앱은 여전히 소수입니다.
          <br />
          이제 탄탄한 앱 기획과 제작은 물론 앱 마케팅 또한 앱의 성공에 핵심
          요소가 되었습니다.
          <br />
          원투애드는 광고주에게 정교하고 효과적인 최적의 모바일 앱 마케팅 플랜을
          제시합니다. 각종 트래킹 툴에서 추출되는 신뢰할 수 있는 데이터에 근거한
          앱 마케팅 컨설팅, 매체 운영, 광고 제작 등을 전문적으로 진행하고
          있습니다.
          <br />
          <br />
          리워드 마케팅, CPI 마케팅, CPA 마케팅, CPM 마케팅을 다수 진행하였으며,
          커머스, 부동산, 교육, 게임 등 다양한 앱 마켓 카테고리에서 모바일
          광고액 집행 순위 최상위권을 차지하는 수많은 대기업들의 모바일 광고
          대행을 진행해 오고 있습니다.
          <br />
          <br />
          스타트업 특유의 열정을 지닌 직원들이 모여 모바일 앱 지형도, 모바일
          시장 흐름, 최신 앱 동향, 앱의 수익구조 및 앱 성공 마케팅 전략에 대해서
          파악하며 현재까지 성장을 지속하고 있으며 함께 미래를 만들어 나가고
          있습니다.
        </p>
      </section>
    </>
  );
}
