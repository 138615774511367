import React from "react";
import icon1 from "../images/5-Clients/13.png";
import icon2 from "../images/5-Clients/14.png";
import icon3 from "../images/5-Clients/15.png";
import icon4 from "../images/5-Clients/16.png";
import icon5 from "../images/5-Clients/17.png";
import icon6 from "../images/5-Clients/18.png";
import icon7 from "../images/5-Clients/19.png";
import icon8 from "../images/5-Clients/20.png";
import icon9 from "../images/5-Clients/21.png";
import icon10 from "../images/5-Clients/22.png";
import icon11 from "../images/5-Clients/23.png";
import icon12 from "../images/5-Clients/24.png";

const listItems = [
  {
    imgFile: icon1,
  },
  {
    imgFile: icon2,
  },
  {
    imgFile: icon3,
  },
  {
    imgFile: icon4,
  },
  {
    imgFile: icon5,
  },
  {
    imgFile: icon6,
  },
  {
    imgFile: icon7,
  },
  {
    imgFile: icon8,
  },
  {
    imgFile: icon9,
  },
  {
    imgFile: icon10,
  },
  {
    imgFile: icon11,
  },
  {
    imgFile: icon12,
  },
];

export default function Clients() {
  return (
    <section
      id="clients"
      className="flex flex-col h-[calc(100vh_-_64px)] min-h-[700px] max-h-[800px] lg:max-h-[1100px] items-center"
    >
      <header className="flex flex-col items-center justify-center px-14 md:px-20">
        <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-center mb-1 mt-10">
          Our Clients
        </h1>
        <h2
          className="md:text-lg lg:text-xl font-medium text-center"
          style={{ wordBreak: "keep-all" }}
        >
          원투애드가 신뢰와 성과로 보답하고 있는 클라이언트입니다.
        </h2>
        <hr className="border-t-[3px] h-[3px] w-[50px] mt-5"></hr>
      </header>

      <ul
        data-scroll
        className="grid grid-cols-3 md:grid-cols-4 md:px-[3%] lg:px-[15%] py-10 md:pb-1 md:py-5 lg:py-8 xl:py-20 items-center justify-center h-full"
      >
        {listItems.map((items) => (
          <li
            key={items.imgFile}
            className="w-[100px] md:w-[110px] xl:w-[130px]"
          >
            <img src={items.imgFile} alt="services" className="w-full" />
          </li>
        ))}
      </ul>
    </section>
  );
}
