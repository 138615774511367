import React from "react";
import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
import bg from "../images/6-Contact/bg.jpg";
import Map from "./Map";
import Footer from "./Footer";
import { coordinateX, coordinateY } from "../const";

export default function Contact() {
  const openKakaoMap = () => {
    window.open(
      `https://map.kakao.com/link/to/원투애드,${coordinateX}, ${coordinateY}`
    );
  };

  return (
    <section
      id="contact"
      className="flex flex-col items-center h-[calc(100vh_-_64px)] min-h-[620px] lg:min-h-[700px] max-h-[1000px] bg-cover bg-center bg-blend-lighten"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundColor: "rgba(255,255,255,0.7)",
      }}
    >
      <header className="flex flex-col items-center px-0 xl:px-[10%] pt-10">
        <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-center mb-1">
          Contact Us
        </h1>
        <h2 className="md:text-lg lg:text-xl font-medium">문의하기</h2>
        <hr className="border-t-[3px] h-[3px] w-[50px] mt-5"></hr>
      </header>

      <main className="flex flex-col items-center w-full h-full ">
        <ul className="flex flex-col justify-center gap-3 sm:gap-7 lg:gap-3 w-full max-w-[1500px] max-h-[300px] m-10 md:m-5 2xl:m-[2%]">
          <li className="w-full flex justify-center items-center">
            <PhoneIcon className="h-6 lg:h-8 opacity-50" />
            <button className="pl-1 text-sm md:text-tiny xl:text-base">
              <a href="tel:+82 10-6291-1871">+82 10-6291-1871</a>
            </button>
          </li>
          <li className="w-full flex justify-center items-center">
            <LocationMarkerIcon className="h-6 lg:h-8 opacity-50" />
            <button
              className="pl-1 text-sm md:text-tiny xl:text-base"
              onClick={openKakaoMap}
            >
              서울 마포구 월드컵북로4길 25 2층
            </button>
          </li>
          <li className="w-full flex justify-center items-center">
            <MailIcon className="h-6 lg:h-8 opacity-50" />
            <button className="pl-1 text-sm md:text-tiny xl:text-base">
              <a href="mailto:sjoh@justonetwo.com">sjoh@justonetwo.com</a>
            </button>
          </li>
        </ul>

        <Map openKakaoMap={openKakaoMap} />
      </main>

      <Footer />
    </section>
  );
}
