import React from "react";
import bg from "../images/1-Home/bg.jpg";

export default function Home() {
  return (
    <section
      id="home"
      className="flex items-center justify-center h-screen bg-cover bg-center bg-blend-darken 
      text-white min-h-[350px] lg:min-h-[500px]"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
    >
      <div
        data-scroll
        className="flex flex-col items-center drop-shadow-lg px-1"
      >
        <h1 className="text-xl md:text-3xl lg:text-4xl xl:text-6xl font-semibold text-center px-[15%] md:px-0">
          Mobile Performance Marketing Agency
        </h1>
        <h2 className="text-xl md:text-3xl lg:text-4xl xl:text-6xl font-semibold text-center">
          for Effective Advertising
        </h2>
        <h3 className="text-sm md:text-lg lg:text-xl xl:text-2xl pt-[3%]">
          원투애드와 함께하면 달라질 수 있습니다.
        </h3>
      </div>
    </section>
  );
}
