import React, { useEffect } from "react";
import "./App.css";
import ScrollOut from "scroll-out";
import Home from "./components/1-Home";
import Intro from "./components/2-Intro";
import Services from "./components/3-Services";
import Sectors from "./components/4-Sectors";
import Clients from "./components/5-Clients";
import Contact from "./components/6-Contact";
import Navbar from "./components/Navbar";

function App() {
  useEffect(() => {
    ScrollOut({
      onShown: function (el) {
        el.animate([{ opacity: 0 }, { opacity: 1 }], 800);
      },
    });
  }, []);

  return (
    <div className="text-main">
      <Navbar />
      <Home />
      <Intro />
      <Services />
      <Sectors />
      <Clients />
      <Contact />
    </div>
  );
}

export default App;
