import React from "react";

export default function Footer() {
  const date = new Date();
  return (
    <footer className="bottom-0 w-full flex flex-col justify-center items-center bg-main text-white text-[0.5rem] py-3 text-center">
      <div>
        Copyright Ⓒ {date.getFullYear()} OnetwoAd Inc. All Rights Reserved.
      </div>
    </footer>
  );
}
