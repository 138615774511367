import React from "react";
import image1 from "../images/4-Sectors/sector1.jpg";
import image2 from "../images/4-Sectors/sector2.jpg";
import image3 from "../images/4-Sectors/sector3.jpg";
import image4 from "../images/4-Sectors/sector4.jpg";
import image5 from "../images/4-Sectors/sector5.jpg";
import image6 from "../images/4-Sectors/sector6.jpg";

const listItems = [
  {
    imgFile: image1,
    contents: "Financial Services",
  },
  {
    imgFile: image2,
    contents: "Game",
  },
  {
    imgFile: image3,
    contents: "Telecommunications",
  },
  {
    imgFile: image4,
    contents: "Education",
  },
  {
    imgFile: image5,
    contents: "Food & Beverages",
  },
  {
    imgFile: image6,
    contents: "Consumer Goods",
  },
];

export default function Sectors() {
  return (
    <section
      id="sectors"
      className="flex flex-col items-center h-[calc(100vh_-_64px)] min-h-[750px] lg:min-h-[700px] max-h-[1100px] bg-grey"
    >
      <header className="flex flex-col items-center justify-center px-14 md:px-20">
        <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-center mb-1 mt-10">
          Industry & Sectors
        </h1>
        <h2
          className="md:text-lg lg:text-xl font-medium text-center"
          style={{ wordBreak: "keep-all" }}
        >
          원투애드는 다양한 산업 부문에 종합 마케팅 솔루션을 제공합니다.
        </h2>
        <hr className="border-t-[3px] h-[3px] w-[50px] mt-5"></hr>
      </header>

      <ul
        data-scroll
        className="grid grid-cols-2 lg:grid-cols-3 gap-x-3 md:gap-x-6 px-[13%] xl:px-[18%] h-full w-full items-center py-16 md:py-5 lg:py-16 max-w-[2500px]"
      >
        {listItems.map((items) => (
          <li
            key={items.contents}
            className="flex flex-col justify-end bg-cover bg-center h-[9rem] md:h-[11rem] xl:h-[12rem] 2xl:h-[12.5rem] rounded-2xl drop-shadow-lg overflow-hidden"
            style={{
              backgroundImage: `url(${items.imgFile})`,
            }}
          >
            <p
              className="text-xs md:text-md lg:text-xl bg-blend-darken 
              text-white py-1 px-2"
              style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
            >
              {items.contents}
            </p>
          </li>
        ))}
      </ul>
    </section>
  );
}
